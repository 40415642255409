import { useEffect, useState } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as JOORLogo } from 'assets/images/logo_joor.svg'
import {
  isAccountTypeRetailer,
  isLiteBrand,
  isProBrand,
  isProRetailer,
  userIsLoggedIn,
} from 'store/currentUser/selectors'

import { ACCOUNTS_HOME, HOME } from 'routes/paths'

import HamburgerDropdown from '././components/HamburgerDropdown/HamburgerDropdown'
import NavbarAccountsDropdown from './components/AccountsDropdown/AccountsDropdown'
import NavbarCartDropdown from './components/CartDropdown/CartDropdown'
import NavbarConnectionsDropdown from './components/ConnectionsDropdown/ConnectionsDropdown'
import ConnectionsMenuOption from './components/ConnectionsMenuIcon/ConnectionsMenuOption'
import DiscoverDropdown from './components/DiscoverDropdown/DiscoverDropdown'
import NavbarExploreDropdown from './components/ExploreDropdown/ExploreDropdown'
import NavbarLocalizeDropdown from './components/LocalizeDropdown/LocalizeDropdown'
import MessageMenuOption from './components/MessageMenuOption/MessageMenuOption'
import NavbarOrdersDropdown from './components/OrdersDropdown/NavbarOrdersDropdown'
import PassportMenuLink from './components/PassportMenuLink/PassportMenuLink'
import NavbarPaymentsDropdown from './components/PaymentsDropdown/PaymentsDropdown'
import ProLiteIndicator from './components/ProLiteIndicator/ProLiteIndicator'
import NavbarProductsDropdown from './components/ProductsDropdown/NavbarProductsDropdown'
import NavbarProfileDropdown from './components/ProfileDropdown/ProfileDropdown'
import ReportingMenuLink from './components/ReportingMenuLink/ReportingMenuLink'
import NavbarReportsDropdown from './components/ReportsDropdown/ReportsDropdown'
import NavbarSettingsDropdown from './components/SettingsDropdown/SettingsDropdown'
import ShopDropdown from './components/ShopDropdown/ShopDropdown'
import SubmissionsListButton from './components/SubmissionsListButton/SubmissionsListButton'
import WorkspacesDropdown from './components/WorkspacesDropdown/WorkspacesDropdown'
import { HAMBURGER_MENU_MAX_WIDTH } from './constants/constants'
import { HomeIds } from './navbarRevamp.ids'
import { INavbarSelectors } from './navbarRevamp.types'
import { isOnAdmin } from './utils/navbar.utils'

const NavbarRevampContainer = styled.div`
  * a {
    color: var(--color-secondary-100);
  }

  * a:hover {
    color: var(--color-secondary-100);
  }
`

const NavbarHeader = styled.div`
  background-color: var(--color-primary-800);
  display: flex;
  position: fixed;
  margin: 0;
  width: 100%;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  z-index: 3010;
  height: 53px;
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-alpha);
`

const HomeLink = styled(Link)`
  border-bottom: none;
  padding: 13px 12px;
  img {
    width: 80px;
    height: auto;
  }
  &:hover {
    background: inherit;
  }
`

const RightOptionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 12px;
`

const NavbarRevamp = () => {
  const { wayfindingNavigation } = useFlags()
  const [shouldRenderHamburger, setShouldRenderHamburger] = useState(
    window.innerWidth <= HAMBURGER_MENU_MAX_WIDTH,
  )
  const location = useLocation()
  const {
    isProBrandAccount,
    isLoggedIn,
    isProRetailerAccount,
    isRetailerAccount,
    isLiteBrandAccount,
  } = useSelector<object, INavbarSelectors>((state) => ({
    isProBrandAccount: isProBrand(state),
    isProRetailerAccount: isProRetailer(state),
    isLoggedIn: userIsLoggedIn(state),
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
  }))
  const homeLink = isRetailerAccount ? HOME : ACCOUNTS_HOME
  const isProAccount = isProBrandAccount || isProRetailerAccount
  useEffect(() => {
    const handleResize = () => {
      setShouldRenderHamburger(window.innerWidth <= HAMBURGER_MENU_MAX_WIDTH)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!isLoggedIn) {
    return (
      <NavbarRevampContainer>
        <NavbarHeader data-testid="navbar">
          <HomeLink to={HOME} data-testid={HomeIds.HomeLinkTrigger}>
            <JOORLogo />
          </HomeLink>
          <RightOptionsContainer>
            <NavbarLocalizeDropdown />
          </RightOptionsContainer>
        </NavbarHeader>
      </NavbarRevampContainer>
    )
  }

  if (wayfindingNavigation && isRetailerAccount && !isProRetailerAccount) {
    return (
      <NavbarRevampContainer>
        <NavbarHeader data-testid="navbar">
          <ProLiteIndicator isProAccount={isProAccount} />
          <HomeLink to={homeLink} data-testid={HomeIds.HomeLinkTrigger}>
            <JOORLogo />
          </HomeLink>
          <ShopDropdown />
          <NavbarOrdersDropdown />
          <NavbarExploreDropdown />
          <RightOptionsContainer>
            <NavbarAccountsDropdown />
            <MessageMenuOption />
            <NavbarCartDropdown />
          </RightOptionsContainer>
        </NavbarHeader>
      </NavbarRevampContainer>
    )
  }

  if (!isOnAdmin(location)) {
    return (
      <NavbarRevampContainer>
        <NavbarHeader data-testid="navbar">
          <ProLiteIndicator isProAccount={isProAccount} />
          <HomeLink to={homeLink} data-testid={HomeIds.HomeLinkTrigger}>
            <JOORLogo />
          </HomeLink>
          <ShopDropdown />
          <NavbarOrdersDropdown />
          <WorkspacesDropdown />
          <DiscoverDropdown />
          <NavbarPaymentsDropdown />
          <NavbarProductsDropdown />
          <NavbarConnectionsDropdown />
          <ReportingMenuLink />
          <NavbarProfileDropdown />
          <NavbarReportsDropdown />
          <PassportMenuLink />
          <SubmissionsListButton />
          <RightOptionsContainer>
            <NavbarAccountsDropdown />
            {shouldRenderHamburger ? (
              <HamburgerDropdown isLiteBrandAccount={isLiteBrandAccount} />
            ) : (
              <>
                <NavbarSettingsDropdown />
                <ConnectionsMenuOption />
                {!isLiteBrandAccount && <MessageMenuOption />}
                {!isLiteBrandAccount && <NavbarCartDropdown />}
                <NavbarLocalizeDropdown />
              </>
            )}
          </RightOptionsContainer>
        </NavbarHeader>
      </NavbarRevampContainer>
    )
  }

  return null
}

export default NavbarRevamp
