import { useEffect } from 'react'

import { Icon, SmallGammaBadge } from '@joor/design-system'
import { useDispatch, useSelector } from 'react-redux'

import { formatNumber } from 'utils/formatters'

import { isAccountTypeBrand, isProRetailer } from 'store/currentUser/selectors'
import {
  setCartInfo,
  setShouldDisableCart,
  setShouldUpdateCart,
} from 'store/shop/actions'
import {
  shouldDisableCart as shouldDisableCartSelector,
  shouldUpdateCart as shouldUpdateCartSelector,
} from 'store/shop/selectors'

import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import {
  CartDropdownContainer,
  CartMenuIconContainer,
  CartMenuLabel,
  QuantityBadge,
} from './CartDropdown.ui'
import { useCartData } from './cartDropdown.queries'
import CartPreview from './components/CartPreview/CartPreview'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { CartDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import {
  INavbarCartDropdown,
  INavbarCartSelectors,
} from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

interface ICustomCartTrigger {
  unitsInCart: number
  isInsideDropdown: boolean
}

const CartMenuIcon = ({
  unitsInCart,
  isInsideDropdown,
}: ICustomCartTrigger) => (
  <>
    <CartMenuIconContainer isInsideDropdown={isInsideDropdown}>
      <Icon iconName="cart" variant="DEFAULT" type="white" plain />
      {unitsInCart > 0 && (
        <QuantityBadge>
          <SmallGammaBadge
            id={CartDropdownIds.CartBadge}
            value={formatNumber(unitsInCart)}
          />
        </QuantityBadge>
      )}
    </CartMenuIconContainer>
    {isInsideDropdown && <CartMenuLabel>{messages.cart}</CartMenuLabel>}
  </>
)
const CustomCartTrigger = ({
  unitsInCart,
  isInsideDropdown,
}: ICustomCartTrigger) => (
  <Trigger
    isInsideDropdown={isInsideDropdown}
    label={
      <CartMenuIcon
        unitsInCart={unitsInCart}
        isInsideDropdown={isInsideDropdown}
      />
    }
    id={CartDropdownIds.CartTrigger}
  />
)

const NavbarCartDropdown = ({
  isInsideDropdown = false,
}: INavbarCartDropdown) => {
  const dispatch = useDispatch()
  const {
    shouldUpdateCart,
    isProRetailerAccount,
    shouldDisableCart,
  } = useSelector<object, INavbarCartSelectors>((state) => ({
    shouldUpdateCart: shouldUpdateCartSelector(state),
    isBrandAccount: isAccountTypeBrand(state),
    isProRetailerAccount: isProRetailer(state),
    shouldDisableCart: shouldDisableCartSelector(state),
  }))
  const {
    orders,
    cartLoading,
    refetchOrdersInCart,
    unitsInCart,
  } = useCartData()

  useEffect(() => {
    if (shouldUpdateCart) {
      refetchOrdersInCart()
      dispatch(setShouldUpdateCart(false))
      dispatch(setShouldDisableCart(false))
    }
  }, [shouldUpdateCart])

  useEffect(() => {
    if (!cartLoading) {
      dispatch(setCartInfo(orders))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLoading])

  if (isProRetailerAccount) {
    return null
  }
  return (
    <CartDropdownContainer
      isInsideDropdown={isInsideDropdown}
      data-testid={CartDropdownIds.CartContainer}
    >
      <NavbarDropdown
        testId={CartDropdownIds.CartTrigger}
        trigger={
          <CustomCartTrigger
            unitsInCart={unitsInCart}
            isInsideDropdown={isInsideDropdown}
          />
        }
        rightPosition
        isInsideDropdown={isInsideDropdown}
      >
        <DropdownMenuGroup>
          <CartPreview
            orders={orders}
            isCartLoading={shouldDisableCart || shouldUpdateCart || cartLoading}
          />
        </DropdownMenuGroup>
      </NavbarDropdown>
    </CartDropdownContainer>
  )
}

export default NavbarCartDropdown
