import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'

import {
  fromGlobalId,
  relayConnectionToArray,
} from 'utils/transformations/graphql'

import { graphene } from 'graphql/tags'
import {
  ALLOW_EDIT_RETAILER_SETTINGS,
  BRAND_ACCOUNT,
  RETAILER_ACCOUNT,
  SUBTYPE_CORE_BRAND,
  SUBTYPE_LITE_BRAND,
  TYPE_BRAND,
  TYPE_RETAILER,
} from 'store/currentUser/selectors'

export const transformUserData = ({ data }) => {
  const { viewer, currentUser } = data

  const fetchedId = get(currentUser, 'id')
  const userId = fetchedId ? parseInt(fromGlobalId(fetchedId).id, 10) : null
  const isLoggedIn = Boolean(fetchedId)
  const accountsUserId = viewer
    ? parseInt(fromGlobalId(get(viewer, 'id')).id, 10)
    : null
  const accountUserPermissions = relayConnectionToArray(
    get(viewer, 'userPermissions'),
  )
  const canViewRetailerSettings = !isUndefined(
    find(
      accountUserPermissions,
      (featureAccess) => featureAccess.key === ALLOW_EDIT_RETAILER_SETTINGS,
    ),
  )

  const accountId = viewer
    ? parseInt(fromGlobalId(get(viewer, 'viewerAccount.id')).id, 10)
    : null
  const typeFromTypename = (typeName) => {
    if (typeName === BRAND_ACCOUNT) {
      return TYPE_BRAND
    } else if (typeName === RETAILER_ACCOUNT) {
      return TYPE_RETAILER
    }
    return 1
  }
  const type = typeFromTypename(get(viewer, 'viewerAccount.__typename'))
  const canViewAssortments = type === 3 && get(viewer, 'assortmentPlanning')
  const limitedAccess =
    get(viewer, 'viewerAccount.subtype') === SUBTYPE_LITE_BRAND
  const coreBrand = get(viewer, 'viewerAccount.subtype') === SUBTYPE_CORE_BRAND
  const integrations = filter(
    get(viewer, 'integrations'),
    (integration) => integration === 'RLM' || integration === 'Kellwood',
  )

  return {
    id: fetchedId,
    userId,
    name: get(currentUser, 'name'),
    dateFormat: get(currentUser, 'dateFormat'),
    email: get(currentUser, 'email'),
    lastLogin: get(currentUser, 'lastLogin'),
    isLoggedIn,
    isInternal: get(currentUser, 'internalUser'),
    accountsUserId,
    assortmentPlanning: get(viewer, 'assortmentPlanning'),
    canViewRetailerSettings,
    isTiedWithBuyerHierarchy: get(viewer, 'tiedWithBuyerHierarchy'),
    displayLanguage: get(currentUser, 'displayLanguage'),
    emailLanguage: get(currentUser, 'emailLanguage'),
    account: {
      accountId,
      profileName: get(viewer, 'viewerAccount.name'),
      logoURL: get(viewer, 'viewerAccount.logo.url', ''),
      type,
      brandCanViewDataPortal: get(viewer, 'viewerAccount.canViewDataPortal'),
      canTransact: get(viewer, 'viewerAccount.canTransact'),
      coreBrand,
      limitedAccess,
      canShop: get(viewer, 'viewerAccount.canShop'),
      canFindBrands: get(viewer, 'viewerAccount.canFindBrands'),
      enableChat: get(viewer, 'viewerAccount.enableChat'),
      canViewAssortments,
      integrations,
      origin: get(viewer, 'viewerAccount.accountCreationSource.externalSource'),
    },
    settings: {
      accountUserPermissions,
    },
  }
}

export const fetchCurrentUser = (client) =>
  client
    .query({
      query: graphene`#graphql
        {
          currentUser {
            id
            name
            dateFormat
            email
            lastLogin
            userType
            internalUser
            emailLanguage
            displayLanguage
          }
          viewer {
            id
            assortmentPlanning
            tiedWithBuyerHierarchy
            integrations
            viewerAccount {
              __typename
              ... on BrandAccount {
                id
                name
                accountCreationSource {
                  externalSource
                }
                logo {
                  id
                  url
                }
                canTransact
                canViewDataPortal
                subtype
                enableChat
              }
              ... on RetailerAccount {
                id
                name
                accountCreationSource {
                  externalSource
                }
                logo {
                  id
                  url
                }
                canShop
                canFindBrands
                enableChat
              }
            }
            userPermissions {
              edges {
                node {
                  id
                  name
                  key
                }
              }
            }
          }
        }
      `,
    })
    .then(transformUserData)
