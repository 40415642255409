import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'

import { RETAILER_PROFILE, WORKSPACE_LIST } from 'routes/paths'

import { useRetailerTitle } from '../../features/LeadGen/RetailerProfile/RetailerProfilePage.hooks'

const REACT_APP_CONTAINER_SELECTOR = '.joor-react'
const REACT_APP_MODAL_CONTAINER_SELECTOR = '#root-popups'
const PENDO_ACTION_BAR_CONTAINER = '#pendo-action-bar-container'
// This class is added in index.html, this class does not come from the Zendesk initialization script
const ZENDESK_MESSAGING_WIDGET = '.zendesk-messaging'

const addReactPageClassName = () => {
  // Add a class to the <div> used by React to mark this as a React page.
  // When going from a React page to a PHP one, LegacyPage will check this
  // class to determine whether we need to refresh the page or not
  const rootContainer = document.querySelector('body > div.joor-react')
  if (rootContainer) {
    document.querySelector('body > div.joor-react').classList.add('react-page')
  }
}

const getNonReactElements = () => {
  const nonReactContainersSelector =
    `body>div:not(${REACT_APP_CONTAINER_SELECTOR})` +
    `:not(${REACT_APP_MODAL_CONTAINER_SELECTOR})` +
    `:not(${ZENDESK_MESSAGING_WIDGET})` +
    `:not(${PENDO_ACTION_BAR_CONTAINER}),` +
    `body>b>div:not(${REACT_APP_CONTAINER_SELECTOR})` +
    `:not(${REACT_APP_MODAL_CONTAINER_SELECTOR})` +
    `:not(${ZENDESK_MESSAGING_WIDGET})` +
    `:not(${PENDO_ACTION_BAR_CONTAINER})`

  return document.querySelectorAll(nonReactContainersSelector)
}

export const hidePhpLoader = () => {
  const cssRule = '.spinner { display: none; }'
  const styleTag = document.createElement('style')
  styleTag.type = 'text/css'
  styleTag.appendChild(document.createTextNode(cssRule))
  styleTag.id = 'hide-php-loader'
  document.head.appendChild(styleTag)
}

export const showPhpLoader = () => {
  const styleTag = document.getElementById('hide-php-loader')
  styleTag?.remove()
}

const hideLegacyContainers = () => {
  // Hide all the <div> tags from PHP, removing them would cause issues with
  // legacy scripts that expect those elements to be there
  const cssRule = '.layout-block, body > .spinner { display: none; }'
  const styleTag = document.createElement('style')

  styleTag.type = 'text/css'
  styleTag.appendChild(document.createTextNode(cssRule))
  document.head.appendChild(styleTag)
  const legacyContainers = getNonReactElements()
  for (let i = 0; i < legacyContainers.length; i += 1) {
    legacyContainers[i].style.display = 'none'
  }
}

const removeLegacySpreadsheets = () => {
  // Get rid of all the <style> tags from PHP
  const links =
    'link[rel=stylesheet][href^="/css/"],link[rel=stylesheet][href^="/september/"]'
  const legacySpreadsheets = document.querySelectorAll(links)
  for (let i = 0; i < legacySpreadsheets.length; i += 1) {
    legacySpreadsheets[i].parentNode.removeChild(legacySpreadsheets[i])
  }
}

const setPageTitle = (path, title) => {
  const pageTitle = title || capitalize(path.split('/')[1])
  document.title = `${pageTitle} | JOOR`
}

const RouterPage = (props) => {
  const { workspaceListPage } = useFlags()
  const { retailerName } = useRetailerTitle(props.path)

  let title = props.title
  if (props.path === WORKSPACE_LIST && workspaceListPage) {
    title = 'Workspaces'
  }
  if (props.path === RETAILER_PROFILE) {
    title = retailerName
  }

  setPageTitle(props.path, title)
  removeLegacySpreadsheets()
  addReactPageClassName()
  hideLegacyContainers()

  document.addEventListener('DOMContentLoaded', () => {
    // Try to hide legacy containers again after the DOM loaded
    hideLegacyContainers()
  })

  return props.children
}

RouterPage.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.element,
}

RouterPage.defaultProps = {
  title: null,
}

export default RouterPage
