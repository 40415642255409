import filter from 'lodash/filter'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'
import { createSelector } from 'reselect'

import { fromGlobalId } from 'utils/transformations/graphql'

import { isAccountTypeRetailer } from 'store/currentUser/selectors'
import { NONE_GROUP_BY_TAG_ID, NONE_SORT_BY_TAG_ID } from 'store/shop/constants'

import {
  EXCLUDE_SOLD_OUT_STYLES_KEY,
  HIDE_SOLD_OUT_STYLES_KEY,
  INVENTORY_AVAILABILITY_FILTER_KEY,
} from 'containers/ProductCatalog/CatalogFilters/filters'

export const getRetailer = (state) => state.shop.retailer
export const getRetailerId = (state) => get(state, 'shop.retailer.id')
export const getRetailerName = (state) =>
  get(state, 'shop.retailer.displayName', '')
export const getDoors = (state) => state.shop.doors
export const getPriceTypes = (state) => state.shop.priceTypes
export const getWarehouse = (state) => state.shop.warehouse
export const getWarehouseId = (state) => get(state.shop.warehouse, 'id')
export const getEventId = (state) => get(state.shop.event, 'id')
export const shouldUpdateCart = (state) => state.shop.shouldUpdateCart
export const shouldDisableCart = (state) => state.shop.shouldDisableCart
export const wasOrderStarted = (state) =>
  Boolean(get(state.shop.retailer, 'id')) ||
  Boolean(get(state.shop.brand, 'id'))
export const hasLocalStorageLoaded = (state) => state.shop.localStorageLoaded
export const getSelectedFilters = (state) => state.shop.selectedFilters
export const getAppliedFilters = (state) => state.shop.appliedFilters
export const shouldFilterSoldOutColors = (state) =>
  get(state, `shop.appliedFilters.${INVENTORY_AVAILABILITY_FILTER_KEY}].id`) ===
  EXCLUDE_SOLD_OUT_STYLES_KEY
export const shouldHideSoldOutColors = (state) =>
  get(state, `shop.appliedFilters.${INVENTORY_AVAILABILITY_FILTER_KEY}].id`) ===
  HIDE_SOLD_OUT_STYLES_KEY
export const getSearchText = (state) => state.shop.searchText
export const getOrderTypeId = (state) => state.shop.orderTypeId

export const getPriceType = ({ shop: { orderPriceType } }) =>
  orderPriceType || null

const getDefaultPriceType = ({ shop: { priceTypes } }) => priceTypes[0] || {}

export const getOrderPriceType = (state) =>
  getPriceType(state) || getDefaultPriceType(state)

export const getBrand = (state) => state.shop.brand
export const getProductsAddedToCart = (state) => {
  const brandId = getBrand(state)?.id
  const retailerId = fromGlobalId(getRetailerId(state))?.id

  const orders = filter(state.shop.cartInfo, (order) => {
    return brandId
      ? order?.brand?.id === brandId
      : fromGlobalId(order?.retailerProfile?.id)?.id === retailerId
  })

  return sumBy(orders, `products.totalProducts`, 0)
}

export const areMultipleOrdersInCart = (state) => {
  const brandId = getBrand(state)?.id
  const retailerId = fromGlobalId(getRetailerId(state))?.id

  const orders = filter(state.shop.cartInfo, (order) => {
    return brandId
      ? order?.brand?.id === brandId
      : fromGlobalId(order?.retailerProfile?.id)?.id === retailerId
  })
  return orders?.length > 1
}

export const getOrdersInCart = (state) => {
  const brandId = getBrand(state)?.id
  const retailerId = fromGlobalId(getRetailerId(state))?.id

  const orders = filter(state.shop.cartInfo, (order) => {
    return brandId
      ? order?.brand?.id === brandId
      : fromGlobalId(order?.retailerProfile?.id)?.id === retailerId
  })
  return orders?.length
}

export const getOrderCurrencyCode = createSelector(
  getOrderPriceType,
  (orderPriceType) => get(orderPriceType, 'currency.code', ''),
)

export const getGroupByTagInfo = (state) => state.shop.groupByTagInfo
export const getSortByTagInfo = (state) => state.shop.sortByTagInfo

export const isGroupByTagInfoSet = createSelector(
  getGroupByTagInfo,
  (groupByTagInfo) => groupByTagInfo.id !== NONE_GROUP_BY_TAG_ID,
)

export const isSortByTagInfoSet = createSelector(
  getSortByTagInfo,
  (sortByTagInfo) => sortByTagInfo?.id !== NONE_SORT_BY_TAG_ID,
)

export const getAccountShoppingForId = (state) =>
  isAccountTypeRetailer(state)
    ? get(getBrand(state), 'id')
    : get(getRetailer(state), 'id')

export const getOrderGroupId = (state) => state.shop.orderGroupId

export const getSelects = (state) => state.shop.selects

export const getSelectedWarehouse = (state) => state.shop.warehouse
