import isEmpty from 'lodash/isEmpty'

import { MediaType } from 'utils/images'
import { isGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { checkElementsRequiredContent } from './StorefrontNavbar.utils'
import {
  COLLECTIONS_WIDGET,
  DOCUMENT_WIDGET,
  IMAGE_WIDGET,
  INFO_WIDGET,
  INSTAGRAM_WIDGET,
  LINESHEET_WIDGET,
  ORB_WIDGET,
  PRODUCT_WIDGET,
  SHOWROOM_BRAND_WIDGET,
  TEXT_WIDGET,
  VIDEO_WIDGET,
  getRowTemplate,
} from 'features/Storefront/constants'

const isLocalId = (id) => (id ? id.startsWith('LOCAL_') : true)

export const getStorefrontUpsertInput = (storefront = {}, pageSettings) => {
  const { id, rows = [], ...storefrontProps } = storefront
  const inputRows = rows.map(
    (
      {
        elements,
        id,
        tooltip: _tooltip,
        settings,
        title,
        template,
        rowType,
        ...rowProps
      },
      index,
    ) => {
      const numberedElements = elements.map((el, index) => ({
        ...el,
        orderNbr: index,
      }))
      const textElements = numberedElements
        .filter((el) => el.elementType === TEXT_WIDGET)
        .map(({ elementType: _elementType, id, content, ...rest }) => {
          const contentWithShowTitle = JSON.stringify({
            ...JSON.parse(content),
            overlayText: settings.showTitle || false,
          })
          return {
            content: contentWithShowTitle,
            ...rest,
            ...(!isLocalId(id) && { id }),
            ...(settings.width
              ? { presentation: JSON.stringify({ width: settings.width }) }
              : {}),
          }
        })
      const videoElements = numberedElements
        .filter((el) => el.elementType === VIDEO_WIDGET)
        .map(({ id, content, orderNbr }) => {
          const {
            autoplay,
            loop,
            aspect,
            position: _position,
            videoId,
            size: _size,
            showTitle: _showTitle,
            ...basicInfo
          } = content
          const presentation = {
            autoplay,
            loop,
            ...(settings.size && { size: settings.size }),
            ...(settings.position && { position: settings.position }),
            aspect: aspect || settings.aspect,
          }
          const formattedPresentation = JSON.stringify(presentation)
          const isGlobal = isGlobalId(videoId)

          return {
            ...basicInfo,
            ...(videoId && {
              videoId: isGlobal ? videoId : toGlobalId('Upload', videoId),
            }),
            presentation: formattedPresentation,
            orderNbr,
            ...(!isLocalId(id) && { id }),
          }
        })
      const imageElements = numberedElements
        .filter((el) => el.elementType === IMAGE_WIDGET && !isEmpty(el.content))
        .map(({ id, content, orderNbr }) => {
          const { imageExternalLink, imageId } = content
          const presentation = {
            ...(settings.size && { size: settings.size }),
            ...(settings.position && { position: settings.position }),
            aspect: settings.aspect,
          }
          const formattedPresentation = JSON.stringify(presentation)

          const isGlobal = isGlobalId(imageId)
          return {
            imageId: isGlobal ? imageId : toGlobalId('Upload', imageId),
            presentation: formattedPresentation,
            orderNbr,
            ...(imageExternalLink && { imageExternalLink }),
            ...(!isLocalId(id) && { id }),
          }
        })
      const collectionElements = numberedElements
        .filter(
          (el) => el.elementType === LINESHEET_WIDGET && !isEmpty(el.content),
        )
        .map(({ id, content, orderNbr }) => {
          const presentation = JSON.stringify(settings)
          const { collectionId, collectionTitle, imageId } = content
          const isGlobal = isGlobalId(imageId)

          return {
            orderNbr,
            presentation,
            collectionId,
            collectionTitle,
            coverId: isGlobal ? imageId : toGlobalId('Upload', imageId),
            ...(!isLocalId(id) && { id }),
          }
        })
      const aboutElements = elements
        .filter((el) => el.elementType === INFO_WIDGET)
        .map(({ id }, elIndex) => {
          const widgetSettings = JSON.stringify(settings)
          return {
            settings: widgetSettings,
            orderNbr: elIndex,
            ...(!id?.startsWith('LOCAL_') && { id }),
          }
        })
      const productElements = numberedElements
        .filter(
          (el) => el.elementType === PRODUCT_WIDGET && !isEmpty(el.content),
        )
        .map(({ id, content, orderNbr }) => {
          const { collectionId, productId } = content
          return {
            orderNbr,
            presentation: JSON.stringify({ showTitle: rowProps.showTitle }),
            ...(collectionId && { collectionId }),
            ...(productId && { productId }),
            ...(!id?.startsWith('LOCAL_') && { id }),
          }
        })
      const lookbookElements = numberedElements
        .filter(
          (el) => el.elementType === DOCUMENT_WIDGET && !isEmpty(el.content),
        )
        .map(({ id, content, orderNbr }) => {
          const { lookbookId, lookbookTitle, imageId } = content
          const isGlobal = isGlobalId(imageId)

          return {
            orderNbr,
            presentation: JSON.stringify({
              ...(settings.size && { size: settings.size }),
              showTitle: settings.showTitle,
              aspect: settings.aspect,
              ...(settings.position && { position: settings.position }),
            }),
            lookbookId,
            lookbookTitle,
            ...(imageId && {
              coverId: isGlobal ? imageId : toGlobalId('Upload', imageId),
            }),
            ...(!id?.startsWith('LOCAL_') && { id }),
          }
        })
      const showroomBrandElements = numberedElements
        .filter(
          (el) =>
            el.elementType === SHOWROOM_BRAND_WIDGET && !isEmpty(el.content),
        )
        .map(({ id, content, orderNbr }) => {
          const presentation = JSON.stringify(settings)
          const { showroomBrandId } = content

          return {
            orderNbr,
            presentation,
            showroomBrandId,
            ...(!isLocalId(id) && { id }),
          }
        })
      const showroomCollectionElements = numberedElements
        .filter(
          (el) => el.elementType === COLLECTIONS_WIDGET && !isEmpty(el.content),
        )
        .map(({ id, content, orderNbr }) => {
          const {
            showroomCollectionId,
            showroomCollectionTitle,
            imageId,
          } = content
          const isGlobal = isGlobalId(imageId)
          const { position, showTitle, aspect } = settings
          return {
            orderNbr,
            presentation: JSON.stringify({
              ...(settings.size && { size: settings.size }),
              showTitle,
              aspect,
              ...(position && { position }),
            }),
            ...(showroomCollectionId && { showroomCollectionId }),
            ...(showroomCollectionTitle && { showroomCollectionTitle }),
            ...(imageId && {
              coverId: isGlobal ? imageId : toGlobalId('Upload', imageId),
            }),
            ...(!id?.startsWith('LOCAL_') && { id }),
          }
        })
      const orb360Elements = numberedElements
        .filter(
          (el) =>
            el.elementType === ORB_WIDGET &&
            !isEmpty(el.content) &&
            el?.content?.externalVideoId,
        )
        .map(({ id, content, orderNbr }) => {
          const { externalVideoId, loop, autoplay } = content
          const { position, showTitle, aspect } = settings

          return {
            orderNbr,
            presentation: JSON.stringify({
              showTitle,
              aspect,
              ...(position && { position }),
              loop,
              autoplay,
            }),
            ...(externalVideoId && { externalVideoId }),
            ...(!id?.startsWith('LOCAL_') && { id }),
          }
        })
      const instagramElements = numberedElements
        .filter(
          (el) => el.elementType === INSTAGRAM_WIDGET && !isEmpty(el.content),
        )
        .map(({ id, content, orderNbr }) => ({
          ...(!id?.startsWith('LOCAL_') && { id }),
          instagramAuthId: content.instagramAuthId,
          orderNbr,
        }))
      return {
        ...rowProps,
        template: getRowTemplate(template, rowType),
        ...(title && settings?.showTitle && { title }),
        orderNbr: index,
        ...(!isLocalId(id) && { id }),
        textElements,
        videoElements,
        aboutElements,
        productElements,
        imageElements,
        collectionElements,
        lookbookElements,
        showroomBrandElements,
        showroomCollectionElements,
        orb360Elements,
        instagramElements,
      }
    },
  )

  const { isNewPage, isOnline, isHome, orderNbr, displayName, uniqueName } =
    pageSettings || storefrontProps

  return {
    ...(!isLocalId(id) && !isNewPage && { id }),
    isOnline,
    isHome,
    orderNbr,
    displayName,
    uniqueName,
    rows: isNewPage ? [] : inputRows,
  }
}

/**
 * Maps text styles to valid upsert input.
 */
export const getStorefrontTextUpsertInput = (textStyles = []) => {
  const inputStyles = textStyles.map((style) => ({
    id: style.id,
    name: style.name,
    size: style.fontSize,
    weight: style.fontWeight,
    typeface: style.fontFamily,
    orderNbr: style.order,
  }))
  return {
    textStyles: inputStyles,
  }
}

export const getStorefrontProfileUpsertInput = (profile = {}) => ({
  mainPictureId:
    profile?.accountMedia.mediaType === MediaType.UNKNOWN
      ? undefined
      : profile?.accountMedia?.id,
  logoId: profile?.logo?.id || null,
  productLineIds: profile?.productLineIds,
  wholesaleMin: parseInt(profile?.wholesaleMin, 10) || 0,
  wholesaleMax: parseInt(profile?.wholesaleMax, 10) || 0,
  retailMin: parseInt(profile?.retailMin, 10) || 0,
  retailMax: parseInt(profile?.retailMax || 0, 10),
  customUrlHandle: profile?.customUrlHandle || '',
  websiteLink: profile?.websiteUrl || '',
  facebookLink: profile?.facebookLink
    ? `https://facebook.com/${profile?.facebookLink}`
    : '',
  instagramHandle: profile?.instagramHandle || '',
  facebookLink: `https://facebook.com/${profile?.facebookLink}` || '',
  instagramHandle: profile?.instagramHandle
    ? `@${profile?.instagramHandle}`
    : '',
  twitterHandle: profile?.twitterHandle || '',
  yearEstablished: parseInt(profile?.yearEstablished, 10) || 0,
  description: profile?.description || '',
})

export const isEmptyRow = (row) => {
  const isTitleEmpty = row?.title?.includes('Section Title')
  const areElementsEmpty = row.elements.every((element) =>
    isEmpty(element.content),
  )
  return isTitleEmpty && areElementsEmpty
}

export const checkInputRequiredElements = (rows) => {
  const requiredElementsErrors = []
  const filteredRows = rows?.filter((row) => !isEmptyRow(row))
  filteredRows.forEach(({ elements }, i) => {
    const rowErrors = []
    elements.forEach(({ elementType, content }) => {
      const requiredContent = checkElementsRequiredContent(elementType, content)
      requiredContent && rowErrors.push(requiredContent)
    })

    if (!!rowErrors.length) {
      const uniqueErrors = [...new Set(rowErrors)]
      requiredElementsErrors.push(
        `Row ${i} has errors, please add the required content: ${uniqueErrors.join()}`,
      )
    }
  })
  return requiredElementsErrors
}
