import { GammaBadge, breakpoints } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { useScreenSize } from 'hooks/screenHooks'
import { isAccountTypeBrand } from 'store/currentUser/selectors'

import { SUBMISSIONS_LIST } from 'routes/paths'

import { SubmissionsListIds } from '../../navbarRevamp.ids'
import MenuLinkWrapper from '../MenuLinkWrapper/MenuLinkWrapper'
import { useNewSubmissions } from './submissionsListButton.hooks'

const StyledMenuLink = styled(MenuLinkWrapper)`
  @media only screen and ${breakpoints.upTo.screen} {
    display: none;
  }
`

const SubmissionsListButton = () => {
  const isBelowBreakpoint = useScreenSize(breakpoints.numeric.screen)
  const { submissionsList } = useFlags()
  const isBrandAccount = useSelector<object, boolean>(isAccountTypeBrand)
  const newSubmissionsCount = useNewSubmissions(
    !submissionsList || isBelowBreakpoint || isBrandAccount,
  )

  if (isBrandAccount || !submissionsList || isBelowBreakpoint) {
    return null
  }

  return (
    <StyledMenuLink
      id={SubmissionsListIds.InterestedInYouButton}
      to={SUBMISSIONS_LIST}
    >
      Interested In You{' '}
      {!!newSubmissionsCount && (
        <span style={{ marginLeft: '10px' }}>
          <GammaBadge value={newSubmissionsCount} />
        </span>
      )}
    </StyledMenuLink>
  )
}

export default SubmissionsListButton
