import { PRODUCT_CATALOG as FEATURE_PREFIX } from 'featurePrefix.ids'

const CATALOG_HEADER = 'catalog-page-header'
const CATALOG_GROUP_BY = 'catalog-group-by-dropdown'
const STYLE_CARD = 'style-card-hover'
const CATALOG_PAGE = 'catalog-page'
const PDP = 'product-detail-page'

export const CatalogHeader = {
  CatalogHeading: `${FEATURE_PREFIX}__${CATALOG_HEADER}__heading`,
  ViewOrderButton: `${FEATURE_PREFIX}__${CATALOG_HEADER}__view-order-button`,
} as const

export const CatalogGroupBy = {
  GroupByDropdown: `${FEATURE_PREFIX}__${CATALOG_GROUP_BY}__group-by-dropdown`,
  GroupByLinesheet: `${FEATURE_PREFIX}__${CATALOG_GROUP_BY}__group-by-linesheet-group`,
  GroupBySilhouette: `${FEATURE_PREFIX}__${CATALOG_GROUP_BY}__group-by-silhouette`,
  GroupByBadge: `${FEATURE_PREFIX}__${CATALOG_GROUP_BY}__group-by-badge`,
} as const

export const StyleCardHover = {
  PreviewButton: `${FEATURE_PREFIX}__${STYLE_CARD}__preview-button`,
  ViewAndQuantifyButton: `${FEATURE_PREFIX}__${STYLE_CARD}__view-and-quantify-button`,
  ViewDetailsButton: `${FEATURE_PREFIX}__${STYLE_CARD}__view-details-button`,
} as const

export const CatalogPage = {
  AddToOrderButton: `${FEATURE_PREFIX}__${CATALOG_PAGE}__add-to-order-button`,
  OrderSplittingWarning: `${FEATURE_PREFIX}__${CATALOG_PAGE}__order-splitting-warning`,
} as const

export const ProductDetailPage = {
  ViewAndQuantifyHeader: `${FEATURE_PREFIX}__${PDP}__view-and-quantify-header`,
  ProductDetailsHeader: `${FEATURE_PREFIX}__${PDP}__product-details-header`,
  StyleDetails: `${FEATURE_PREFIX}__${PDP}__style-details-accordion-toggle-button`,
  SizeDetails: `${FEATURE_PREFIX}__${PDP}__size-details-accordion-toggle-button`,
  SizeAddtoOrder: `${FEATURE_PREFIX}__${PDP}__size-add-to-order-button`,
  BackToCatalog: `${FEATURE_PREFIX}__${PDP}__back-button`,
} as const
