import React from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import compose from 'lodash/flowRight'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  isAccountTypeBrand,
  isProRetailer as isProRetailerSelector,
} from 'store/currentUser/selectors'

import StartOrderModal from 'modals/OrderList/StartOrderModal'
import {
  PRODUCT_CATALOG,
  SHOP_BY_LINESHEET,
  SHOWROOM_COLLECTION_LIST,
  SHOWROOM_STYLEBOARD_ADD,
  SHOWROOM_STYLEBOARD_LIST,
} from 'routes/paths'

import { INavbarShopDropdown } from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuOption from '../DropdownMenuOption/DropdownMenuOption'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import withModal from 'containers/ModalHandler'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ShopDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarShopDropdownContainer = styled.div`
  display: flex;
`
const ShowroomGroup = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={SHOWROOM_COLLECTION_LIST}
      message={messages.looks}
      id={ShopDropdownIds.ShopOptionLooks}
    />
    <DropdownMenuTextLink
      to={SHOWROOM_STYLEBOARD_LIST}
      message={messages.styleboards}
      id={ShopDropdownIds.ShopOptionStyleBoards}
    />
    <DropdownMenuTextLink
      to={SHOWROOM_STYLEBOARD_ADD}
      message={messages.createStyleboard}
      id={ShopDropdownIds.ShopOptionCreateStyleboard}
    />
  </DropdownMenuGroup>
)

const ShopOption = ({
  isProRetailer,
  openDynamicModal,
}: { isProRetailer: boolean } & INavbarShopDropdown) =>
  isProRetailer ? (
    <DropdownMenuTextLink
      message={messages.browseCatalog}
      id={ShopDropdownIds.ShopOptionBrowseCatalog}
      to={PRODUCT_CATALOG}
    />
  ) : (
    <DropdownMenuOption
      message={messages.startAnOrder}
      id={ShopDropdownIds.ShopOptionStartOrder}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        openDynamicModal({
          component: StartOrderModal,
        })
      }}
    />
  )
const NavbarShopDropdown = ({ openDynamicModal }: INavbarShopDropdown) => {
  const { virtualShowroom } = useFlags()
  const isBrandAccount = useSelector<object, boolean>(isAccountTypeBrand)
  const isProRetailer = useSelector<object, boolean>(isProRetailerSelector)
  if (isBrandAccount) {
    return null
  }
  return (
    <NavbarShopDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger label={messages.shop} id={ShopDropdownIds.ShopTrigger} />
        }
      >
        <DropdownMenuGroup>
          <ShopOption
            isProRetailer={isProRetailer}
            openDynamicModal={openDynamicModal}
          />
          {!isProRetailer && (
            <DropdownMenuTextLink
              message={messages.linesheets}
              to={SHOP_BY_LINESHEET}
            />
          )}
        </DropdownMenuGroup>
        {virtualShowroom && <ShowroomGroup />}
      </NavbarDropdown>
    </NavbarShopDropdownContainer>
  )
}
export default compose(withModal)(NavbarShopDropdown)
