import { useQuery } from '@apollo/client'
import sumBy from 'lodash/sumBy'
import { useSelector } from 'react-redux'

import { Order, ordersInProgressDocument } from '__generated__/atlas-types'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { userIsLoggedIn } from 'store/currentUser/selectors'

atlas`#graphql
  query ordersInProgress {
    orders(filters: { status: IN_PROGRESS }) {
      edges {
        node {
          id
          referenceNumber
          priceType {
            id
            currency {
              id
              code
            }
          }
          brand {
            id
            name
          }
          retailerProfile {
            id
            name
            displayName
          }
          products {
            totalProducts
            totalUnits
            totalVariant
          }
          financials {
            dueTotal {
              maxPrice
            }
          }
        }
      }
    }
  }
`

export type OrderInCart = Pick<
  Order,
  | 'id'
  | 'referenceNumber'
  | 'priceType'
  | 'brand'
  | 'retailerProfile'
  | 'products'
  | 'financials'
>

type CartDataResultType = {
  orders: OrderInCart[]
  cartLoading: boolean
  refetchOrdersInCart: () => void
  unitsInCart: number
}

export const useCartData = (): CartDataResultType => {
  const isUserLoggedIn = useSelector(userIsLoggedIn)

  const { data, loading, refetch } = useQuery(ordersInProgressDocument, {
    client: atlasClient,
    skip: !isUserLoggedIn,
    notifyOnNetworkStatusChange: true,
  })

  const orders = relayConnectionToArray(data?.orders)

  return {
    orders,
    cartLoading: loading,
    refetchOrdersInCart: refetch,
    unitsInCart: sumBy(orders, 'products.totalUnits'),
  }
}

export default useCartData
